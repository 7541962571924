.overlay-container{
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.popup-container{
    position: relative;
    width: 350px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 3px solid brown;
    border-radius: 30px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 30px;
    z-index: 10;
    text-align: center;
}

.popup-close-button{
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    background-color: transparent;
    color: brown;
    font-weight: 700;
    font-size: 1.2rem;
}

.popup-close-button{
    cursor: pointer;
}

.popup-input-container{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    color: brown;
    font-size: 1.2rem;
    font-weight: 600;
}

.popup-input-container input{
    border: 2px solid brown;
    border-radius: 20px;
    padding: 3px 20px;
    font-size: 1.1rem;
    font-style: italic;
    color: brown;
    background-color: transparent;
    margin-bottom: 10px;
}

.popup-buttons{
    display: flex;
    grid-gap: 20px;
    justify-content: center;
}

.popup-buttons button{
    padding: 5px 10px;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
}

.popup-confirm-button{
    background-color: brown;
    color: white;
    border: 2px solid transparent;
    transition: all .5s;
}

.popup-confirm-button:hover{
    border: 2px solid black;
    background: rgba(146, 47, 47, 0.3);
    color: black;
}