.main-container{
    width: 500px;
    min-height: 500px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius:20px;
    border: 2px solid rgb(133, 57, 57);
    margin: 30px auto;
    box-shadow: -10px 10px 20px black;
    position: relative;
}

.title{
    text-align: center;
    color: brown;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding: 20px;
    font-size: 2.5rem;
}

.add-task-container{
    background-color: brown;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
}

.task-input{
    border-radius: 20px;
    font-size: 1rem;
    padding: 3px 10px;
}

.add-task-button{
    background-color: white;
    border-radius: 20px;
    font-size: 1rem;
    padding: 3px 10px;
    border: 2px solid transparent;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: all .75s;
}

.add-task-button:hover{
    border: 2px solid white;
    background-color: brown;
    color: white;
    padding: 3px 20px;
}

.task-counter{
    padding: 2px 5px;
    font-style: italic;
    text-align: center;
    background-color: #303030;
    color: white;
    width: 150px;
    border-radius: 0px 0px 15px 15px;
    margin: 0px auto;
    font-size: 0.8rem;
}

.task-list-container{
    border-top:3px solid #a52a2a;
    margin: 5px auto 50px auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
}

.task-item{
    background-color: brown;
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 10fr 1fr 1fr 1fr;
    border-radius: 10px;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    
}

.task-title{
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: left;
}

.completed{
    text-decoration: line-through;
    color: grey;
}

.complete-button, .edit-button, .delete-button {
    border-radius: 20px;
    background-color: transparent;
    color: white;
    padding: 1px;
    border-color: transparent;
    transition: all .5s;
    font-size: 1.2rem;
}


.complete-button:hover, .edit-button:hover,.delete-button:hover{
    color: rgb(217, 164, 93);
    cursor: pointer;
}

.clear-button{
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.clear-button button{
    border: 2px solid white;
    background-color: brown;
    color: white;
    border-radius: 20px;
    font-size: 1rem;
    padding: 3px 10px;
    border: 2px solid transparent;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: all .75s;
    display: block;
    margin: auto;
}

.clear-button button:hover{
    border: 2px solid brown;
    color: brown;
    background-color: white;
    padding: 3px 30px;
}

@media screen and (max-width:500px) {
    .main-container{
        width: 400px;
    }
}

