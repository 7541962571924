*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body{
  background-image: url(./assets/todo-app.jpg);
  background-size: auto 110vh;
  background-position: center top;
}

